import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{staticClass:"navigation-menu",attrs:{"accordion":"","multiple":"","flat":""}},[(_vm.menuItems.length)?_vm._l((_vm.menuItems),function(item){return _c(VExpansionPanel,{key:item.icon,attrs:{"readonly":!item.submenu}},[_c(VExpansionPanelHeader,{attrs:{"expand-icon":item.submenu ? '$expand' : 'mdi-blank'}},[_c(item.submenu ? 'span' : 'router-link',_vm._b({tag:"component"},'component',{ to: item.submenu ? undefined : item.link },false),[_c(VIcon,{staticClass:"me-3"},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(item.title))])],1)],1),(item.submenu)?_c(VExpansionPanelContent,[_c(VList,{attrs:{"flat":""}},_vm._l((item.submenu),function(subItem,key){return _c(VListItem,{key:key},[_c(VListItemContent,{staticClass:"text-caption"},[(subItem.submenu)?_c('submenu-item',{attrs:{"title":subItem.title,"items":subItem.submenu}}):_c('router-link',{attrs:{"to":subItem.link}},[_vm._v(" "+_vm._s(subItem.title)+" ")])],1)],1)}),1)],1):_vm._e()],1)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }